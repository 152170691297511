/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import SEO from '../components/seo';
import Career from '../containers/career';
import HeroArea from '../containers/hero';
import CarouselHero from '../containers/CarouselHero/CarouselHero';
import ContactArea from '../containers/contact-area';
import Footer from '../containers/Layout/Footer';
import Header from '../containers/Layout/Header';
import Methodology from '../containers/methodology';
import BenefitArea from '../containers/benefit-area';
import TestimonialSection from '../containers/testimonial-area';
import TestimonialVideoArea from '../containers/testimonial-video';
import EcommerceCourses from '../containers/EcommerceCourses';

const InPersonPage = ({ location }) => (
  <>
    <SEO
      title="Cursos Preparatórios para Concursos Públicos e Militares - Garra - Presencial e OnLine"
      location={location}
    />
    <Header />
    <CarouselHero />
    <HeroArea />
    <Career />
    <EcommerceCourses />
    <BenefitArea />
    <Methodology />
    <TestimonialVideoArea />
    <TestimonialSection />
    <Methodology />
    <ContactArea />
    <Footer />
  </>
);

InPersonPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default InPersonPage;
