import styled, { css } from 'styled-components';
import { device } from '../../theme';

export const Container = styled.div`
  ${({ theme }) => css`
    .slick-slider {
      position: relative;
      display: block;
      touch-action: pan-y;
      user-select: none;
      -webkit-tap-highlight-color: transparent;
    }
    .slick-list {
      position: relative;
      display: block;
      padding: 0;
      margin: 0;
      overflow: hidden;
      &:focus {
        outline: none;
      }
      &.dragging {
        cursor: pointer;
        cursor: hand;
      }
    }
    .slick-slider {
      .slick-track,
      .slick-list {
        transform: translate3d(0, 0, 0);
      }
    }
    .slick-track {
      position: relative;
      top: 0;
      left: 0;
      display: flex;
      &::before,
      &::after {
        display: table;
        content: '';
      }
      &::after {
        clear: both;
      }
      .slick-loading .slick-track {
        visibility: hidden;
      }
    }
    .slick-slide {
      display: none;
      height: 100%;
      min-height: 1px;
      outline: none !important;
      img,
      div {
        outline: none !important;
      }
      img {
        display: block;
      }
      .slick-slide.slick-loading img {
        display: none;
      }
      &.dragging img {
        pointer-events: none;
      }
    }
    .slick-initialized .slick-slide {
      display: block;
    }
    .slick-loading .slick-slide {
      visibility: hidden;
    }
    .slick-vertical .slick-slide {
      display: block;
      height: auto;
      border: 1px solid transparent;
    }
    .slick-arrow.slick-hidden {
      display: none;
    }

    .slick-arrow {
      position: absolute;
      top: 50%;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      padding: 10px;
      margin-top: -40px;
      color: ${theme.colors.white};
      cursor: pointer;
      background-color: ${theme.colors.primary};
      border: 0;
      outline: none;
      transition: all 0.5s ease;
      appearance: none;
      @media ${device.small} {
        visibility: hidden;
      }
      &.slick-prev {
        left: 0;
        &:before {
          content: '';
        }
      }
      &.slick-next {
        right: 0;
        &:before {
          content: '';
        }
      }

      &:hover {
        background-color: ${theme.colors.primaryDark};
        color: ${theme.colors.secondary};
      }
      &:active,
      &:focus {
        background-color: ${theme.colors.primaryDark};
      }
      &.slick-disabled {
        cursor: default;
        background-color: ${theme.colors.gray} !important;
        box-shadow: none;
      }
      svg {
        display: block;
      }
    }
  `}
`;
